import logo from "./logo.svg";
import "./App.css";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import Location from "./Component/Location/Location";
import ResponsiveExample from "./Component/Header/offCanvas";
import FoodMenu from "./Component/FoodMenu/FoodMenu";
import AboutUs from "./Component/About/About";
import Detail from "./Component/ProductDetails/Detail";
import Home from "./Component/Home/Home";
import { Routes, Route } from "react-router-dom";
import MultipleItems from "./Component/SampleSlider/SampleSlider";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/MultipleItems" element={<MultipleItems />} />
      <Route path="/locations" element={<Location />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/menu" element={<FoodMenu />} />
    </Routes>
  );
}

export default App;
