// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colorClass{
    color: #74c3d5;
}

.colorClass:hover{
    color: #fd0;
}`, "",{"version":3,"sources":["webpack://./src/Component/Location/location.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".colorClass{\r\n    color: #74c3d5;\r\n}\r\n\r\n.colorClass:hover{\r\n    color: #fd0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
