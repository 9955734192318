import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Home.css";
import MultipleItems from "../SampleSlider/SampleSlider";

const Home = () => {
  const [scrollAmount, setScrollAmount] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollAmount(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const moveAmount = scrollAmount / 20;

  return (
    <div>
      <div className="bg-class-home  h-[120vh]  sm:h-[160vh] xl:h-[210vh]  relative ">
        <div className="">
          <Header />
        </div>

        <div className="child-1">
          <MultipleItems />
        </div>
      </div>

      <div className="max-w-[100%] overflow-hidden    absolute  bottom-[-139%]  sm:bottom-[-110%] md:bottom-[-89%]  xl:bottom-[-160%] 2xl:bottom-[-120%]    xxl:bottom-[-100%]    py-[150px]">
        <div className="move-text-2   py-[100px]  ">
          <div style={{ transform: `translateX(-${moveAmount}px)` }}>
            NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM
            PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
          </div>
          <div style={{ transform: `translateX(${moveAmount}px)` }}>
            NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM
            PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex justify-end sm:mt-[200px] z-10  relative ">
          <div className="home-pic z-100 relative ">
            <img src={require("./home34.png")} alt="" />

            <div className="absolute element-h ">
              <img src={require("./h.webp")} alt="" className="" />
            </div>

            <div className="element-hand ">
              <img src={require("./hand.webp")} alt="" className="hangImg" />
            </div>
            <div className=" element-home  ">
              <img
                src={require("./Layer 7.png")}
                alt=""
                className="rotate-class"
              />
            </div>
          </div>
          <div className="bg-[#fd0] cursor-pointer p-[30px] left-[54%] 2xl:left-[50%]  text-[#075c40]  top-[25%] 2xl:top-[10%]   text-[20px] sm:text-[30px]  text-center font-bold uppercase rounded-[50%] absolute ">
            Order <br /> Now
          </div>
          <div className="mt-[-20px]">
            <div className="  hidden lg:block   text-[40px] leading-[40px] text-[#075c40] font-bold ">
              HEALTHY AND <br /> <span className="text-[50px]">DELICIOUS</span>
            </div>
            <div className="  hidden lg:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="66"
                height="133"
                viewBox="0 0 66 133"
                fill="none"
              >
                <path
                  d="M1.08212 131.429C10.7442 123.386 21.2624 116.507 29.9656 107.318C38.7537 98.0072 46.3245 87.2773 51.8237 75.6991C62.9073 52.422 64.8832 25.8936 56.0215 1.55721C55.5663 0.386939 57.5461 0.0868183 57.956 1.11674C68.5301 25.8132 67.1065 53.093 56.0594 77.4434C50.5734 89.5909 43.0656 100.564 33.8643 110.179C24.748 119.673 14.5082 128.663 1.8809 132.752C1.207 133.017 0.358518 132.02 1.08212 131.429Z"
                  fill="#003B4A"
                ></path>
              </svg>
              <div className="mt-[-24px]  ml-[-16px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="41"
                  viewBox="0 0 50 41"
                  fill="none"
                >
                  <path
                    d="M0.896112 29.8208C6.07821 20.3341 10.9641 10.6023 16.8237 1.48534C18.6701 -1.43645 23.7578 2.17113 21.8186 5.04547C16.3611 13.0561 11.8497 21.6439 6.69838 29.8344C20.2882 33.0317 34.2691 34.3203 47.8589 37.5176C49.5344 37.9054 49.7791 40.6777 47.7676 40.3056C32.8692 37.3766 17.6705 36.6369 2.83951 33.4847C1.08885 33.1522 -0.0143553 31.417 0.896112 29.8208Z"
                    fill="#003B4A"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className=" slidOuter">
          <div className="    move-text-home-1 ">
            <div style={{ transform: `translateX(-${moveAmount}px)` }}>
              Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
              pizza,
            </div>
            <div style={{ transform: `translateX(${moveAmount}px)` }}>
              Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
              pizza,
            </div>
          </div>
        </div>
      </div>

      <div className="relative      mb-[200px] md:mb-[400px]  flex justify-center items-center  ">
        <div className=" relative z-10 text-[40px]  sm:text-[70px]  md:text-[80px] lg:text-[120px] xxl:text-[120px] pt-[70px] md:pt-[120px]   justify-center items-center  leading-[60px] md:leading-[100px] lg:leading-[140px]    xl:leading-[180px] text-center  font-bold text-[#075c40] ">
          AT PINZARIA
          <div
            className="text-white"
            style={{
              textShadow:
                "2px 2px 2px green, -2px -2px 2px green, 2px -2px 2px #075c40, -2px 2px 2px green",
            }}
          >
            WE BELIVEVE
          </div>


          FRESH IS BEST
        </div>
        <img src={require("./home3.jpg")} alt="" className="absolute left-[20%] top-[50%]  hidden lg:block" />

        <div className="absolute right-[0px]  hidden lg:block        ">
          <img src={require("./home3.jpg")} alt="" />
        </div>
        <div className="absolute top-0 md:transform-rotate-15       trans-class ">
          <img src={require("./home2.png")} alt="" className="at-img" />
        </div>

        <div className="bg-[#fd0] cursor-pointer  z-100   z-class  px-[30px] py-[40px]  rounded-[50%] uppercase text-[green] hidden lg:block  text-[20px] font-bold leading-[20px] text-center absolute  bottom-[-180px]  left-[65%] ">
          see what <br /> makes <br /> pinza! so <br />
          great
        </div>
      </div>
      <div className="max-w-[100%] overflow-hidden  last-bg pt-[195px] pb-[150px] ">
        <div className="move-text-1 py-[100px]   ">
          <div
            className=""
            style={{ transform: `translateX(-${moveAmount}px)` }}
          >
            NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM
            PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
          </div>
          <div
            className=""
            style={{ transform: `translateX(${moveAmount}px)` }}
          >
            NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM
            PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
          </div>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
