import React from "react";
import "./footer.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";
import 'react-phone-number-input/style.css'

const Footer = () => {
  return (
    <div className=" font-bold bg-[#075c40]  pb-[50px] ">

      <div className="container  w-[95%] xl:w-[1170px]  mx-auto  ">
        <div className="contact-container flex flex-col  justify-center items-center lg:flex-row     ">
          <div className="left-part w-[350px] mx-auto  text-center lg:text-start w-full lg:w-[50%]">
          <Link to="/">
            <img
              src={require("./logo.png")}
              alt=""
              className="w-[175px]   xl:w-[200px]"
            />
          </Link>
            <p className=" text-start   lg:text-center" >
              <a href="/locations">Order Now</a> <a href="/menu">Menu</a>
            </p>
          </div>
          <div className="right-part  w-full lg:w-[50%]">
            <div className="formidable_column width-12  last">
              <div>
                <h6 className="email-signup   text-start  md:text-center lg:text-start ">
                  GET THE LATEST ON PINZARIA
                </h6>
              </div>
            </div>

            <div className="formidable_row">
              <div className="formidable_column ">
                <div className="elemen">
                  <div class="  flex lg:block  input text-whiite">
                    <TextField
                      label="Email Address *"
                      className=" email-place     bg-[#04432e] h-[60px] md:mx-auto   w-[320px] md:w-[410px] lg:w-full"
                      InputLabelProps={{ style: { color: 'white' } }} 
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="gap-[20px] flex mt-[20px]  md:mx-auto   w-[320px] md:w-[410px]  lg:w-full ">
              
              <div className="lg:w-full">
                <TextField
                  label="Phone Number *"
                  className=" email-place    bg-[#04432e] h-[60px]   w-[230px]  md:w-[300px] lg:w-full"
                  InputLabelProps={{ style: { color: 'white' } }} 

                />
              </div>
            </div>

            <div className="flex   gap-[20px] lg:gap-[50px]   w-[320px] md:w-[410px] md:mx-auto lg:w-full   mt-[20px] ">
              <div className="send-text  flex justify-center items-center ">SEND</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex   flex-col lg:flex-row   gap-[30px] lg:gap[0px]    justify-start   items-start md:justify-between md:items-center  text-white  px-[20px] md:px-[50px]  mt-[50px] ">
      <div className="lg:hidden    flex gap-[10px] justify-between ">
          <div>
            <FaSquareInstagram  size={36} />
          </div>
          <div>
            <FaSquareFacebook size={36} />
          </div>
          <div>
            <AiFillTikTok size={38} />
          </div>
        </div>
        <div>Email: <a href="mailto:contact@pinzaria.com">CONTACT@PINZARIA.COM</a> | Tel: <a href="tel:+18885958010">(888) 595-8010</a><br></br><p>How did we do? <u><a href="https://survey.flyraven.io/pinzaria">Let us know!</a></u></p></div>

        <div className="   hidden lg:flex gap-[10px] justify-between ">
          <div>
            <FaSquareInstagram size={36} />
          </div>
          <div>
            <FaSquareFacebook size={36} />
          </div>
          <div>
            <AiFillTikTok size={38} />
          </div>
        </div>

        <div className="text-white text-start">
          <div>© 2024 - 2025 PINZARIA. All rights reserved. <a href="/privacy-policy"><u>Privacy Policy</u></a>.</div>
          <div>Site by <a href="https://redvmx.com"><u>RedVMX</u></a></div>
        </div>

      </div>
    </div>
  );
};

export default Footer;
