// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.food-text {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  /* color: #075c40; */
  line-height: 1;
  text-align: center;
}

.transition {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.order-class {
  display: flex;
}

.order-img {
  transition: transform 0.3s ease;
}

.order-img:hover {
  transform: scale(1.2);
  /* Adjust the scale factor as needed */
}

.order-class {
  transition: opacity 0.3s ease;
}

.order-class.visible {
  opacity: 1;
}

.order-class.invisible {
  opacity: 0;
}

.scrollDiv::-webkit-scrollbar {
  display: none;
}

@media (max-width:1024px) {

  .food-text {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #075c40;
    line-height: 1;
    text-align: center;
  }

}`, "",{"version":3,"sources":["webpack://./src/Component/FoodMenu/food.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE;IACE,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,cAAc;IACd,cAAc;IACd,kBAAkB;EACpB;;AAEF","sourcesContent":[".food-text {\r\n  font-size: 1.5rem;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n  /* color: #075c40; */\r\n  line-height: 1;\r\n  text-align: center;\r\n}\r\n\r\n.transition {\r\n  transition: opacity 0.2s ease, transform 0.2s ease;\r\n}\r\n\r\n.order-class {\r\n  display: flex;\r\n}\r\n\r\n.order-img {\r\n  transition: transform 0.3s ease;\r\n}\r\n\r\n.order-img:hover {\r\n  transform: scale(1.2);\r\n  /* Adjust the scale factor as needed */\r\n}\r\n\r\n.order-class {\r\n  transition: opacity 0.3s ease;\r\n}\r\n\r\n.order-class.visible {\r\n  opacity: 1;\r\n}\r\n\r\n.order-class.invisible {\r\n  opacity: 0;\r\n}\r\n\r\n.scrollDiv::-webkit-scrollbar {\r\n  display: none;\r\n}\r\n\r\n@media (max-width:1024px) {\r\n\r\n  .food-text {\r\n    font-size: 1rem;\r\n    font-weight: 700;\r\n    text-transform: uppercase;\r\n    color: #075c40;\r\n    line-height: 1;\r\n    text-align: center;\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
