import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./sliderSamp.css";
import { GrLinkNext } from "react-icons/gr";
import { IoArrowBack } from "react-icons/io5";
function MultipleItems() {
  const [data] = useState([
    {
      image: require("../FoodMenu/foodImages/m2.webp"),

      type: "meaty",
      heading1: "Al Pastor",
      heading2: "50",
      heading3: "aed",
      heading4: "Chicken",
      text: "Chipotle Tomato Sauce, Sliced Chicken Al Pastor, A blend of Mozzarella and Monterey Jack Cheese, Al Pastor Roasted Pineapple Chunks, Sliced Red Onion with a drizzle of Chipotle Crema and a sprinkle of Fresh Chopped Cilantro.",
    },
    {
      image: require("../FoodMenu/foodImages/m2.webp"),
      type: "meaty",

      heading1: "Chicken",
      heading2: "47",
      heading3: "aed",
      heading4: "Shawarma",
      text: "Garlic Labneh Sauce, Sliced Chicken Shawarma, Mozzarella Cheese, Pickles, Onions topped with Fresh Chopped Parsley and Sumac.",
    },
    {
      image: require("../FoodMenu/foodImages/m2.webp"),
      type: "most popular",

      heading1: "Truffle",
      heading2: "55",
      heading3: "aed",
      heading4: "Salami",
      text: "Black Truffle Sauce, Mozzarella Cheese, Pecorino, Spianata and Chili Flakes",
    },
    {
      image: require("../FoodMenu/foodImages/m3.png"),
      type: "meaty",

      heading1: "The Cheese",
      heading2: "65",
      heading3: "aed",
      heading4: "Burger",
      text: "Tomato Sauce, BBQ Sauce, Mozzarella Cheese, Aged Yellow Cheddar, Wagyu Balls, Pickled Gherkins, Red Onions, Spring Onions, Topped with House Sauce, White Sesame Seeds, a side of Shredded lettuce.",
    },
    {
      image: require("../FoodMenu/foodImages/m4.webp"),
      type: "meaty",

      heading1: "Buffalo",
      heading2: "47",
      heading3: "aed",
      heading4: "Chicken",
      text: "Spicy Tomato Sauce, Mozzarella, Aged Yellow Cheddar, Juicy Chunks of Buffalo Chicken, Red Onions, Spring Onions and choice of Ranch Sauce or Bleu Cheese Sauce.",
    },
    {
      image: require("../FoodMenu/foodImages/m5.png"),
      type: "meaty",

      heading1: "Shish",
      heading2: "47",
      heading3: "aed",
      heading4: "Tawook",
      text: "Garlic Labneh Base, Mozzarella & Kashkaval Cheese mix, Chunks of Shish Tawook Chicken, Pickles, Onions, Fried Potatoes, Garlic Labneh Drizzle and Fresh Chopped Parsley.",
    },

    {
      image: require("../FoodMenu/foodImages/m2.webp"),
      type: "meaty",

      heading1: "Truffle",
      heading2: "55",
      heading3: "aed",
      heading4: "Salami",
      text: "Black Truffle Sauce, Mozzarella Cheese, Pecorino, Spianata and Chili Flakes",
    },
    {
      image: require("../FoodMenu/foodImages/m6.webp"),
      type: "meaty",

      heading1: "Chicken",
      heading2: "44",
      heading3: "aed",
      heading4: "Fajitas",
      text: "Tomato Sauce, Mozzarella Cheese, Cajun Chicken, Grilled Peppers, Fresh Onion, topped with fresh Mexican Guacamole, Cilantro and Sour Cream with a side of fresh Mexican Salsa.",
    },
    {
      image: require("../FoodMenu/foodImages/hm12.webp"),
      type: "meaty",

      heading1: "Pepperoni",
      heading2: "47",
      heading3: "aed",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni Salami.",
    },
    {
      image: require("../FoodMenu/foodImages/hm13.webp"),
      type: "meaty",

      heading1: "Pesto",
      heading2: "47",
      heading3: "aed",
      text: "Pesto, Mozzarella Cheese, Wagyu Sausage, Portobello Mushrooms topped with Parmigiano Cheese and Pine Nuts.",
    },
    {
      image: require("../FoodMenu/foodImages/m9.png"),
      type: "meaty",

      heading1: "Spicy",
      heading2: "50",
      heading3: "aed",
      heading4: "Salami",
      text: "Spicy Tomato Sauce, Mozzarella Cheese, Pecorino, Spianata and Chili Flakes",
    },
    {
      image: require("../FoodMenu/foodImages/m7.webp"),
      type: "most popular",

      heading1: "Pepperoni",
      heading2: "47",
      heading3: "aed",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni Salami.",
    },
    {
      image: require("../FoodMenu/foodImages/m10.png"),
      type: "Vegetarian",

      heading1: "Pinzafied",
      heading2: "50",
      heading3: "aed",
      heading4: "Margherita",
      text: "Tomato Sauce, Cherry Tomatoes, Burrata Cheese (Burrata Cheese is not baked and served fresh) topped with Basil.",
    },
    {
      image: require("../FoodMenu/foodImages/m11.png"),
      type: "meaty",

      heading1: "Tandoori",
      heading2: "47",
      heading3: "aed",
      heading4: "Chicken",
      text: "Tomato Sauce with Ground Masala, Mozzarella Cheese, Fresh Onion, Tandoori Chicken, Fresh Jalapeños, topped with cilantro. (Chutney on the side).",
    },
    {
      image: require("../FoodMenu/foodImages/m12.png"),
      type: "sides",

      heading1: "Chili",
      heading2: "45",
      heading3: "aed",
      heading4: "Truffled Garlic",
      text: "Housemade Buttery Garlic Sauce, Truffle Cream, Mozzarella Cheese, Grana Padano, Topped with Parley & Chili Flakes.",
    },
    {
      image: require("../FoodMenu/foodImages/m13.png"),
      type: "Vegetarian",

      heading1: "Veggielicious",
      heading2: "42",
      heading3: "aed",
      text: "Tomato Sauce, Mozzarella Cheese, Black Olives, Portobello Mushrooms and Mixed Capsicum. Can be made Vegan by swapping to Vegan Mozzarella.",
    },
    {
      image: require("../FoodMenu/foodImages/m14.png"),
      type: "seafood",

      heading1: "Pinza",
      heading2: "48",
      heading3: "aed",
      heading4: "Maré",
      text: "Tomato Sauce, Mozzarella Cheese, Mixed Capsicum, Seasoned Prawns and topped with Parsley and Cajun Mayonnaise.",
    },
    {
      image: require("../FoodMenu/foodImages/m15.png"),
      type: "dessert",

      heading1: "Nutella",
      heading2: "28",
      heading3: "aed",
      heading4: "Solo",
      text: "Nutella Spread",
    },
    {
      image: require("../FoodMenu/foodImages/m16.png"),
      type: "meaty",

      heading1: "Aloha",
      heading2: "50",
      heading3: "aed",
      text: "Mozzarella Cheese, Caramelized Onions, 18 hrs Pulled Beef, Fresh Pineapple, Goat Cheese, Jalapeño topped with Housemade Chipotle Sauce.",
    },
    {
      image: require("../FoodMenu/foodImages/m17.png"),
      type: "meaty",

      heading1: "BBQ",
      heading2: "47",
      heading3: "aed",
      heading4: "Chicken",
      text: "Tomato Sauce, Mozzarella, Aged Yellow Cheddar, House Smoked Chicken, Red Onions, Jalapeños, Spring Onions, Fresh Chopped Coriander and Topped with Housemade BBQ Sauce.",
    },
    {
      image: require("../FoodMenu/foodImages/m18.png"),
      type: "meaty",

      heading1: "Montuori",
      heading2: "47",
      heading3: "aed",
      text: "Tomato Sauce, Bresaola topped with Parmigiano Cheese and Arugula.",
    },
    {
      image: require("../FoodMenu/foodImages/m19.png"),
      type: "meaty",

      heading1: "Musakhan",
      heading2: "47",
      heading3: "aed",
      heading4: "Chicken",

      text: "Caramelized Sumac Onions, Pulled Sumac Chicken, Toasted Pine Seeds, Jalapeño Tahina Drizzle, Sprinkled with Fresh Chopped Parsley & Sumac Powder.",
    },
    {
      image: require("../FoodMenu/foodImages/m20.png"),
      type: "meaty",

      heading1: "Smokin’",
      heading2: "60",
      heading3: "aed",
      heading4: "Hot",

      text: "Spicy Tomato Sauce, Mozzarella & Aged Cheddar Cheese, Smoked Turkey, Caramelized Onions, Jalapeños, Sweet Roquito Pepper Pearls, topped with Garlic Chives from Badia Farms.",
    },
    {
      image: require("../FoodMenu/foodImages/m21.png"),
      type: "meaty",

      heading1: "Bresaola",
      heading2: "45",
      heading3: "aed",
      heading4: "Aglio",

      text: "Garlic Butter, Pecorino, Bresaola, Rocca Leaves.",
    },
    {
      image: require("../FoodMenu/foodImages/m22.png"),
      type: "meaty",

      heading1: "Spizy",
      heading2: "47",
      heading3: "aed",
      text: "Tomato Sauce, Mozzarella Cheese, Wagyu Sausage, Portobello Mushrooms, Caramelized Onions topped with Chili Flakes and Fennel Seeds.",
    },
    {
      image: require("../FoodMenu/foodImages/m23.png"),
      type: "meaty",

      heading1: "Supremo",
      heading2: "55",
      heading3: "aed",
      text: "Spicy Tomato Sauce, Mozzarella cheese, Pulled Beef, Pepperoni, Spianata, Bacon, Onions, Green Peppers, topped with Chili Flakes.",
    },
    {
      image: require("../FoodMenu/foodImages/m24.png"),
      type: "meaty",

      heading1: "The ",
      heading2: "55",
      heading3: "aed",
      heading4: "Hangover",

      text: "Tomato Sauce, Mozzarella Cheese, Salami Pepperoni, Italian Spianata Piccante, Fresh Onions, Jalapeños, Wagyu Sausage and topped with Chili Flakes.",
    },
    {
      image: require("../FoodMenu/foodImages/m25.png"),
      type: "Vegetarian",

      heading1: "Truffle",
      heading2: "50",
      heading3: "aed",
      heading4: "Goodness",

      text: "Black Truffle Sauce, Mozzarella Cheese, Portobello Mushrooms and topped with Fresh Parsley (no tomato sauce). Can be made Vegan by swapping to Vegan Mozzarella.",
    },
    {
      image: require("../FoodMenu/foodImages/m26.png"),
      type: "",

      heading1: "Totally",
      heading2: "57",
      heading3: "aed",
      heading4: "Twisted",

      text: "Tomato Sauce, Mozzarella Cheese, Mixed Capsicum, Black Olives, Wagyu Sausage and Basil.",
    },
    {
      image: require("../FoodMenu/foodImages/27.webp"),
      type: "vegan",

      heading1: "El",
      heading2: "27",
      heading3: "aed",
      heading4: "Padrino",

      text: "Tomato Sauce, El Padrino Spicy Sauce, topped with Garlic Chips, Chili & Parsley.",
    },
    {
      image: require("../FoodMenu/foodImages/28.png"),
      type: "vegan",

      heading1: "OMV Oh",
      heading2: "45",
      heading3: "aed",
      heading4: "My Vegan",

      text: "Tomato Sauce, Vegan Mozzarella Cheese, Portobello Mushrooms, Zucchini, Caramelized Onions, Mixed Capsicum topped with Vegan Mexicano Chipotle Sausage.",
    },
    {
      image: require("../FoodMenu/foodImages/29.png"),
      type: "vegan",

      heading1: "The Faux",
      heading2: "45",
      heading3: "aed",
      heading4: "Meat Lover",

      text: "Tomato Sauce, Vegan Mozzarella Cheese, Mixed Capsicum, Black Olives, Cherry Tomatoes, Vegan Apple Maple Sausage topped with Fennel Seeds.",
    },
    {
      image: require("../FoodMenu/foodImages/30.png"),
      type: "vegan",

      heading1: "Truffled",
      heading2: "60",
      heading3: "aed",
      heading4: "Vegan",

      text: "Tomato Sauce, Portobello Mushrooms, Zucchini, Caramelized Onions, Vegan Mushroom Deli Slice and Vegan Truffle Sauce",
    },
    {
      image: require("../FoodMenu/foodImages/31.png"),
      type: "vegan",

      heading1: "Vegan ",
      heading2: "22",
      heading3: "aed",
      heading4: "Cheesy Garlic",

      text: "Vegan Mozzarella Cheese, Housemade Vegan Buttery Garlic Sauce.",
    },
    {
      image: require("../FoodMenu/foodImages/32.png"),
      type: "vegan",

      heading1: "Vegan Chili ",
      heading2: "45",
      heading3: "aed",
      heading4: "Truffled Garlic",

      text: "Housemade Vegan Garlic Butter, Truffle Cream, Vegan Mozzarella Cheese, Topped with Parsley & Chili Flakes.",
    },
    {
      image: require("../FoodMenu/foodImages/33.png"),
      type: "vegan",

      heading1: "Vegan ",
      heading2: "15",
      heading3: "aed",
      heading4: "Garlic",

      text: "Housemade Vegan Buttery Garlic Sauce.",
    },
    {
      image: require("../FoodMenu/foodImages/34.png"),
      type: "Vegetarian",

      heading1: "Amalfi ",
      heading2: "42",
      heading3: "aed",
      text: "Tomato Sauce, Zucchini Slices, Caramelized Onions, Cherry Tomatoes, Black Olives, Feta Cheese sprinkled with Oregano.",
    },
    {
      image: require("../FoodMenu/foodImages/35.png"),
      type: "Vegetarian",

      heading1: "Amore Alla ",
      heading2: "39",
      heading3: "aed",
      heading4: "Parmagiana",

      text: "Tomato Sauce, Mozzarella Cheese, Roasted Aubergine, sprinkled with Parmigiano Cheese and Fresh Basil.",
    },
    {
      image: require("../FoodMenu/foodImages/36.png"),
      type: "Vegetarian",

      heading1: "Margherita",
      heading2: "37",
      heading3: "aed",
      heading4: "‘The Classic’",

      text: "Tomato Sauce, Mozzarella Cheese, Fresh basil and sprinkled with Oregano. Can be made Vegan by swapping to Vegan Mozzarella.",
    },
    {
      image: require("../FoodMenu/foodImages/37.png"),
      type: "Vegetarian",

      heading1: "El ",
      heading2: "45",
      heading3: "aed",
      heading4: "Vegetariano",

      text: "Tomato Sauce, Mozzarella Cheese, Fresh Onion, Fresh Jalapeno, Vegan Mexican Chipotle Sausage, topped with vegan Harissa Mayo. Can be made Vegan by swapping to Vegan Mozzarella.",
    },
    {
      image: require("../FoodMenu/foodImages/38.png"),
      type: "Vegetarian",

      heading1: "Formaggio ",
      heading2: "39",
      heading3: "aed",
      text: "Pecorino Cheese, Mozzarella Cheese, Crushed Black pepper and fresh basil (no tomato sauce).",
    },
    {
      image: require("../FoodMenu/foodImages/39.png"),
      type: "Vegetarian",

      heading1: "Gorgonzolla ",
      heading2: "50",
      heading3: "aed",
      text: "Gorgonzola Cheese, Mozzarella Cheese, Caramelized Onions, Fresh Pears, Crushed Walnuts topped with Honey.",
    },

    {
      image: require("../FoodMenu/foodImages/40.webp"),
      type: "Vegetarian",

      heading1: "Shrooms ",
      heading2: "40",
      heading3: "aed",
      text: "Tomato Sauce, Mozzarella Cheese, Portobello Mushrooms and sprinkled with Rosemary. Can be made Vegan by swapping to Vegan Mozzarella.",
    },

    {
      image: require("../FoodMenu/foodImages/41.png"),
      type: "dessert",

      heading1: "Lotus ",
      heading2: "35",
      heading3: "aed",
      text: "Lotus Biscoff Spread, Sprinkled with Lotus Biscoff Crumb and Topped with a Lotus Biscoff Biscuit.",
    },

    {
      image: require("../FoodMenu/foodImages/42.png"),
      type: "dessert",

      heading1: "Nutella ",
      heading2: "30",
      heading3: "aed",
      heading4: "Banana",
      text: "Nutella Spread and Fresh Sliced Banana",
    },

    {
      image: require("../FoodMenu/foodImages/43.png"),
      type: "Vegetarian",

      heading1: "Chili ",
      heading2: "48",
      heading3: "aed",
      heading4: "Truffled Garlic",
      text: "Housemade Buttery Garlic Sauce, Truffle Cream, Mozzarella Cheese, Grana Padano, Topped with Parsley & Chili Flakes. Vegan option available.",
    },

    {
      image: require("../FoodMenu/foodImages/44.png"),
      type: "sides",

      heading1: "Garlic",
      heading2: "15",
      heading3: "aed",
      heading4: "Pinza",
      text: "Housemade Buttery Garlic Sauce (Delicious).",
    },
    {
      image: require("../FoodMenu/foodImages/43.png"),
      type: "Vegetarian",

      heading1: "Chili ",
      heading2: "48",
      heading3: "aed",
      heading4: "Truffled Garlic",
      text: "Housemade Buttery Garlic Sauce, Truffle Cream, Mozzarella Cheese, Grana Padano, Topped with Parsley & Chili Flakes. Vegan option available.",
    },

    {
      image: require("../FoodMenu/foodImages/45.png"),
      type: "sides",

      heading1: "Cheesy",
      heading2: "30",
      heading3: "aed",
      heading4: "Garlic",
      text: "Mozzarella Cheese, Housemade Buttery Garlic Sauce (To Die For).",
    },
    {
      image: require("../FoodMenu/foodImages/46.png"),
      type: "sides",

      heading1: "Free Range",
      heading2: "30",
      heading3: "aed",
      heading4: "Chicken Tenders",
      text: "5 Pieces of hand breaded Chicken Tenders. We only use fresh chicken breast.",
    },
    {
      image: require("../FoodMenu/foodImages/47.png"),
      type: "sides",

      heading1: "Crispers",
      heading2: "20",
      heading3: "aed",
      text: "Perfect for Dipping.",
    },
    {
      image: require("../FoodMenu/foodImages/45.png"),
      type: "sides",

      heading1: "Sweet",
      heading2: "18",
      heading3: "aed",
      heading4: "Potato Fries",
      text: "Sweet and Crispy.",
    },
    {
      image: require("../FoodMenu/foodImages/46.png"),
      type: "sides",

      heading1: "French",
      heading2: "18",
      heading3: "aed",
      heading4: "Fries",
      text: "Delicious, Crisp French Fries.",
    },
    {
      image: require("../FoodMenu/foodImages/47.png"),
      type: "sides",

      heading1: "Cajun",
      heading2: "18",
      heading3: "aed",
      heading4: "Fries",
      text: "Delicious, Crisp French Fries, sprinkled with Cajun Spices.",
    },
  ]);

  const [activeIndex, setActiveIndex] = useState(2); // Start with index 2

  const renderSlides = () => {
    const totalSlides = 50; // Total number of slides
    const slidesToShow = 5; // Number of slides visible at a time
    const centerIndex = Math.floor(slidesToShow / 2); // Index of the center slide

    const slides = data.map((item, index) => {
      let margin;
      let transfor;
      let scale = 1;
      let zIndex = 1;
      let orderNowText = '';
      // Calculate margin based on position relative to the center slide
      const distanceFromCenter = Math.abs(index - activeIndex);
      let transform = 0;
      if (index === activeIndex) {
        margin = 50; // Center slide

      } else if (distanceFromCenter === 1) {
        // margin = window.innerWidth <= 767 ? 70 : 150; // Slides adjacent to center
        margin = 70;
        transfor = 15
      } else {
        // margin = window.innerWidth <= 767 ? 130 : 250;
        margin = 150; // Other slides
        transfor = -15
      }
      if (index < activeIndex) {
        transform = -15;
      } else if (index > activeIndex) {
        transform = 15;
      }
      else {
        // Active slide
        scale = 1.2; // Zoom in on the active slide
        zIndex = 22;
        orderNowText = <div className="bg-[#fd0]  p-[10px] md:p-[20px] rounded-[50%]  z-10 absolute  left-[30%]  text-center text-[#075c40] bottom-[0%]  uppercase  text-[12px] md:text-[20px] font-bold ">Order   <br /> Now</div>;
      }
      return (
        <div key={index} className=" relative ">
          <img
            src={item.image} // Using dynamic index
            alt=""
            className=""
            style={{ marginTop: `${margin}px`, transform: `rotate(${transform}deg)  scale(${scale})   `, }}
          />
          {orderNowText}

        </div>
      );
    });

    return slides;
  };

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    afterChange: (index) => setActiveIndex(index),
    responsive: [
      {
        breakpoint: 1258,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="previosu">
        <button onClick={onClick}>
          <GrLinkNext color="white" className="btnIcon" size={30} fill="white" />
        </button>
      </div>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="next">
        <button onClick={onClick}>
          <IoArrowBack color="white" className="btnIcon" size={30} />
        </button>
      </div>
    );
  };
  const getCenterHeading = () => {
    if (data.length > 0) {
      return data[activeIndex].heading1;
    }
    return "";
  };
  const [animateSlider, setAnimateSlider] = useState(true);

  useEffect(() => {
    // Disable animation after first render
    setAnimateSlider(false);
  }, []);
  const getCenterHeading1 = () => {
    if (data.length > 0) {
      return data[activeIndex].heading2;
    }
    return "";
  };
  const getCenterHeading2 = () => {
    if (data.length > 0) {
      return data[activeIndex].heading3;
    }
    return "";
  };
  return (
    <div className={`relative pb-[20px] 2xl:pt-[20px] ${animateSlider ? 'animate-slide-in' : ''}`}>
      <div className="arraow">
        <div className="ml-[15px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            className="arrowback"
          >
            <path
              d="M1.01958 45.9407C2.71928 40.6105 3.79559 35.1215 6.1285 29.9953C8.49688 24.8139 11.6542 19.8976 15.5079 15.6942C23.2508 7.23213 33.858 1.98456 45.3746 1.57465C45.9333 1.56692 45.7258 0.699952 45.2326 0.702919C33.281 0.466499 22.2716 5.61174 14.0801 14.2381C9.98944 18.5311 6.72136 23.462 4.29662 28.8648C1.90738 34.2123 -0.0859817 39.9391 0.340649 45.8321C0.34424 46.1544 0.897023 46.338 1.01958 45.9407Z"
              fill="#E8E6D9"
            ></path>
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="none"
            className="arrowMian"
          >
            <path
              d="M15.9848 19.2382C19.0311 15.5147 22.2281 11.8725 25.0086 7.93143C25.905 6.68139 23.5664 5.18617 22.7052 6.46656C20.3137 10.0568 17.5259 13.3533 15.0091 16.8474C11.4182 11.7769 8.54926 6.22598 4.95844 1.15549C4.51836 0.529263 3.33405 0.891867 3.82383 1.65929C7.52276 7.31433 10.369 13.4593 14.1487 19.0492C14.5785 19.7157 15.4787 19.8806 15.9848 19.2382Z"
              fill="#E8E6D9"
            ></path>
          </svg>
        </div>

        <div className="uppercase">
          <span className="text-[15px] sm:text-[30px]  text-[#075c40] font-bold">{getCenterHeading()}</span>{" "}
          <span className="text-[15px] sm:text-[30px] text-[#fd0]  font-bold">
            {getCenterHeading1()}
          </span>{" "}
          <span className="text-[#fd0] text-[20px]  font-bold">
            {getCenterHeading2()}
          </span>
        </div>
      </div>


      <div className="arraow1">
        <div className="uppercase text-center leading-[12px] lg:leading-[25px] 2xl:leading-[30px] ml-[30px] sm:ml-[50px] ">
          <span className=" text-[8px] leading-[12px] sm:text-[12px] md:text-[15px] text-center text-[#075c40] font-bold">we are <br /> famous for <br /> our dough!  </span>{" "}

        </div>
        <div className="ml-[15px] ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            className="arrowback"
          >
            <path
              d="M1.01958 45.9407C2.71928 40.6105 3.79559 35.1215 6.1285 29.9953C8.49688 24.8139 11.6542 19.8976 15.5079 15.6942C23.2508 7.23213 33.858 1.98456 45.3746 1.57465C45.9333 1.56692 45.7258 0.699952 45.2326 0.702919C33.281 0.466499 22.2716 5.61174 14.0801 14.2381C9.98944 18.5311 6.72136 23.462 4.29662 28.8648C1.90738 34.2123 -0.0859817 39.9391 0.340649 45.8321C0.34424 46.1544 0.897023 46.338 1.01958 45.9407Z"
              fill="#E8E6D9"
            ></path>
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="none"
            className="arrowMian"
          >
            <path
              d="M15.9848 19.2382C19.0311 15.5147 22.2281 11.8725 25.0086 7.93143C25.905 6.68139 23.5664 5.18617 22.7052 6.46656C20.3137 10.0568 17.5259 13.3533 15.0091 16.8474C11.4182 11.7769 8.54926 6.22598 4.95844 1.15549C4.51836 0.529263 3.33405 0.891867 3.82383 1.65929C7.52276 7.31433 10.369 13.4593 14.1487 19.0492C14.5785 19.7157 15.4787 19.8806 15.9848 19.2382Z"
              fill="#E8E6D9"
            ></path>
          </svg>
        </div>


      </div>

      <div className="uppercase text-center pb-[20px] 2xl:pb-[50px] 2xl:pt-[50px] text-white  text-[50px] lg:text-[80px] 2xl:text-[120px]  font-bold leading-[92px] ">
        i'm craving{" "}
        <span className="text-[#fd0]   border-b-[10px] border-b border-[#fd0] border-dashed ">
          {getCenterHeading()}
        </span>
      </div>
      <div className="slider-container ">
        <Slider
          {...settings}
          initialSlide={activeIndex}
          prevArrow={<NextArrow />}
          nextArrow={<PrevArrow />}
        >
          {renderSlides()}
        </Slider>
      </div>
    </div>
  );
}

export default MultipleItems;
