// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileView{
    display: none;
}



@media (max-width:992px) {
    .mobileView{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/Component/Header/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;;AAIA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".mobileView{\r\n    display: none;\r\n}\r\n\r\n\r\n\r\n@media (max-width:992px) {\r\n    .mobileView{\r\n        display: block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
