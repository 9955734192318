import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { IoSearch } from "react-icons/io5";
import FoodCard from "./FoodCard";
import "./food.css";

const FoodMenu = () => {
  const [value, setValue] = useState("");
  const [headerValue, setHeader] = useState("all");

  const header = [
    {
      name: "pinzas",
    },
    {
      name: "sides",
    },
    {
      name: "desserts",
    },
    {
      name: "beverages",
    },
  ];
  console.log("headerValue", headerValue);
  const [data] = useState([
    {
      image: require("./foodImages/m1.webp"),
      type: "pinzas",
      heading1: "Classica",
      heading2: "$19.99",
      heading3: "",
      heading4: "",
      text: "Tomatoes Sauce, Cherry Tomato, Fresh Mozzarella, Topped with Fresh Basil",
    },
    {
      image: require("./foodImages/m2.webp"),
      type: "pinzas",
      heading1: "La Gricia",
      heading2: "$19.99",
      heading3: "",
      heading4: "",
      text: "Garlic Paste, Mozzarella Cheese, Topped with Parsley and Pecorino Romano Cheese",
    },
    {
      image: require("./foodImages/m2.webp"),
      type: "pinzas",
      heading1: "Porcini",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "White Truffle and Mushroom Paste, Caramelized Onions, Mozzarella Cheese, Topped with Parsley and Pepper Flakes",
    },
    {
      image: require("./foodImages/m3.png"),
      type: "pinzas",
      heading1: "Vegetariana",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, Black Olives, Mushrooms, Onions, Green Peppers, Topped with Oregano and Pecorino Romano Cheese",
    },
    {
      image: require("./foodImages/m4.webp"),
      type: "pinzas",
      heading1: "Bufalina",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "Buffalo Sauce mixed with Tomato Sauce, Mozzarella Cheese, Buffalo Chicken, Red Onions, Topped with Pecorino Romano Cheese",
    },
    {
      image: require("./foodImages/m5.png"),
      type: "pinzas",
      heading1: "Pollo Griglia",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, BBQ Chicken, Red Onions, Jalapenos",
    },

    {
      image: require("./foodImages/m2.webp"),
      type: "pinzas",
      heading1: "La Gianduia",
      heading2: "$17.99",
      heading3: "",
      heading4: "",
      text: "Nutella Spread, topped with Powdered Sugar",
    },
    {
      image: require("./foodImages/m6.webp"),
      type: "pinzas",
      heading1: "Pasticcio De Carne",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni, Sausage, Ham, Meatballs",
    },
    {
      image: require("./foodImages/m7.webp"),
      type: "pinzas",
      heading1: "Cacio E Pepe",
      heading2: "$22.99",
      heading3: "",
      text: "Hot Chilly Truffle, Garlic Paste, Mozzarella Cheese, topped with Parsley and Pepper Flakes",
    },
    {
      image: require("./foodImages/m8.webp"),
      type: "pinzas",
      heading1: "Suprema",
      heading2: "$22.99",
      heading3: "",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni, Sausage, Onions, Green Peppers, Mushrooms, topped with Pecorino Romano Cheese",
    },
    {
      image: require("./foodImages/m9.png"),
      type: "pinzas",
      heading1: "La Bianca",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "Black Truffle and Mushroom, Garlic, Olive oil, Fresh Mozzarella, topped with Fresh Basil and Pecorino Romano Cheese",
    },
    {
      image: require("./foodImages/m7.webp"),
      type: "pinzas",
      heading1: "Spizy",
      heading2: "22.99",
      heading3: "",
      text: "Hot Chilly Truffle, Mozzarella Cheese, Sausage, Mushrooms, Caramelized Onions, Topped Fresh Basil and Red Pepper Flakes",
    },
    {
      image: require("./foodImages/m10.png"),
      type: "pinzas",
      heading1: "Pesto Basilico",
      heading2: "$22.99",
      heading3: "",
      heading4: "",
      text: "Ranch, Pesto Sauce, Garlic Paste, Fresh Mozzarella, Basil Leaves, and Red pepper Flakes",
    },
    {
      image: require("./foodImages/m11.png"),
      type: "sides",
      heading1: "Garlic Bread Sticks",
      heading2: "$4.99",
      heading3: "",
      heading4: "",
      text: "Served with Marinara",
    },
    {
      image: require("./foodImages/m12.png"),
      type: "sides",
      heading1: "Original French Fries",
      heading2: "$4.99",
      heading3: "",
      heading4: "",
      text: "Served with Honey Mustard",
    },
    {
      image: require("./foodImages/m13.png"),
      type: "sides",
      heading1: "Boardwalk Fries",
      heading2: "$4.99",
      heading3: "",
      text: "Topped in Salt and Vinegar",
    },
    {
      image: require("./foodImages/m14.png"),
      type: "sides",
      heading1: "Garlic Parm Fries",
      heading2: "$4.99",
      heading3: "",
      heading4: "",
      text: "Topped in Garlic Sauce and Parsley",
    },
    {
      image: require("./foodImages/m16.png"),
      type: "sides",
      heading1: "Truffle Fries",
      heading2: "$4.99",
      heading3: "",
      heading4: "",
      text: "Tossed in Truffle and Garlic Paste topped with Parsley, served with ketchup",
    },
    {
      image: require("./foodImages/m16.png"),
      type: "sides",
      heading1: "Chicken Tenders",
      heading2: "$8.99",
      heading3: "",
      text: "Choice Sauce: Ranch, Blue Cheese, or Honey Mustard",
    },
    {
      image: require("./foodImages/m17.png"),
      type: "sides",
      heading1: "Make It A Combo",
      heading2: "$12.99",
      heading3: "",
      heading4: "",
      text: "Chicken Tenders, Original Fries, & a Soda",
    },
    {
      image: require("./foodImages/m18.png"),
      type: "desserts",
      heading1: "Cookies",
      heading2: "$2.99",
      heading3: "",
      text: "",
    },
    {
      image: require("./foodImages/m19.png"),
      type: "desserts",
      heading1: "Brownie",
      heading2: "$3.99",
      heading3: "",
      heading4: "",
      text: "Caramelized Sumac Onions, Pulled Sumac Chicken, Toasted Pine Seeds, Jalapeño Tahina Drizzle, Sprinkled with Fresh Chopped Parsley & Sumac Powder.",
    },
    {
      image: require("./foodImages/m20.png"),
      type: "desserts",
      heading1: "Cannolis",
      heading2: "$5.99",
      heading3: "",
      heading4: "",
      text: "",
    },
    {
      image: require("./foodImages/m21.png"),
      type: "desserts",
      heading1: "La Gianduia Dippers",
      heading2: "$9.99",
      heading3: "",
      heading4: "",
      text: "Nutella Spread, topped with Powdered Sugar",
    },
    {
      image: require("./foodImages/m22.png"),
      type: "beverages",
      heading1: "San Pellegrino",
      heading2: "$2.99",
      heading3: "",
      text: "",
    },
    {
      image: require("./foodImages/m23.png"),
      type: "beverages",
      heading1: "Can of Coke",
      heading2: "$2.49",
      heading3: "",
      text: "",
    },
    {
      image: require("./foodImages/m24.png"),
      type: "beverages",
      heading1: "Can of Diet Coke",
      heading2: "$2.49",
      heading3: "",
      heading4: "",
      text: "",
    },
    {
      image: require("./foodImages/m25.png"),
      type: "beverages",
      heading1: "Can of Sprite",
      heading2: "$2.49",
      heading3: "",
      heading4: "",
      text: "",
    },
  ]);
  const [activeHeader, setActiveHeader] = useState(null);

  const handleHeader = (name) => {
    setActiveHeader(name);
  };
  const filteredData = data.filter((item) => {
    const matchesHeader =
      value.toLowerCase() === "all" ||
      item.heading1.toLowerCase().includes(value.toLowerCase());
    const matchesType = headerValue === "" || item.type === headerValue;

    // If headerValue is "All", show all products
    return (
      matchesHeader && (headerValue.toLowerCase() === "all" || matchesType)
    );
  });
  const [filteredLength, setFilteredLength] = useState(0);

  useEffect(() => {
    // Calculate and store the length of filtered data for the selected header
    if (headerValue) {
      const length = filteredData.filter(data => data.type === headerValue).length;
      setFilteredLength(length);
    }
  }, [filteredData, headerValue]);



  return (
    <div>
      <Header />

      <div className="py-[100px]  w-[95%] xl:w-[1170px] mx-auto ">
        <div className="flex   justify-between max-w-[600px]    border-b-[5px] border-dashed border-[#74c3d5] ">
          <input
            type="text"
            placeholder="TYPE WHAT YOU ARE LOOKING FOR"
            onChange={(e) => setValue(e.target.value)}
            class="text-[#74c3d5] font-bold text-[26px] w-[80%]   leading-[45px]  border-none outline-none W-[90%] bg-transparent border-b border-dashed border-gray-400 focus:border-black"
          />
          <IoSearch fill="#74c3d5" size={36} />
        </div>

        <div className="mt-[100px]  h-[84px] scrollDiv flex justify-start gap-[70px] items-center max-w-[100%] overflow-scroll ">
          <div
            className={`flex relative   cursor-pointer  text-[36px] font-bold leading-[36px] justify-between w-[50px] ${headerValue === "all"
              ? "border-b-[5px] border-dashed border-[#74c3d5] text-[#74c3d5]"
              : "text-[#075c40]"
              }     "`}
            onClick={() => setHeader("all")}
          >
            All{" "}

            {
              headerValue === "all" ? <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-[50px] mt-[-20px]  text-green-900">
                51
              </span> : ""
            }

          </div>
          <div className="flex gap-[15px] justify-center items-center  ">
            {header.map((item, index) => {
              const filteredLength = filteredData.filter(data => data.type === item.name).length;
              return (
                <>
                  <div
                    key={index}
                    className={`food-text cursor-pointer pb-[10px] ${headerValue === item.name
                      ? "border-b-[5px] border-dashed border-[#74c3d5] text-[#74c3d5] "
                      : "text-[#075c40]"
                      }`}
                    onClick={() => setHeader(item.name)}
                  >
                    {item.name}
                  </div>

                  <div className="bg-[#fd0]   rounded-[50%]   ">
                    {headerValue === item.name && <div className="py-[5px] px-[10px]  text-[20px] font-bold text-[#075c40] ">{filteredLength}</div>}
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="mt-[100px]">
          <div className="px-[20px] grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 gap-10">
            {filteredData.map((data, index) => (
              <FoodCard key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FoodMenu;
