import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LocationCard from "./locationCard";

const Location = () => {
  return (
    <div>
      <Header />
      <div className="py-[200px]  px-[20px] md:px-[50px] lg:px-[100px] ">
        <div className="  text-[60px] md:text-8xl uppercase mb-8 leading-10 text-[green]  font-semibold  text-start ">
          EAST COAST{" "}
          <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-1 mt-[-30px]  text-green-900">
            5
          </span>
        </div>
        <div className="grid  grid-cols-1 lg:grid-cols-2  gap-[20px] md:gap-8">
          <LocationCard  code="washington-dc" name="Washington D.C." sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
          <LocationCard  code="alexandria-va" name="Alexandria, VA" sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
          <LocationCard  code="tysons-va" name="Tysons Corner, VA" sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
          <LocationCard  code="arlington-va" name="Arlington, VA" sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
          <LocationCard  code="leesburg-va" name="Leesburg, VA" sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
        </div>
        <div className=" text-[40px] md:text-8xl uppercase mb-8 leading-10 text-[green]  fontClass  font-bold md:font-semibold mt-[200px] text-start ">
          WEST COAST{" "}
          <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-1 mt-[-30px]  text-green-900">
            2
          </span>
        </div>
        <div className="grid  grid-cols-1 lg:grid-cols-2  gap-[20px] md:gap-8">
          <LocationCard  code="losangeles-ca" name="Los Angeles, CA" sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
          <LocationCard  code="sanfrancisco-ca" name="Los Angeles, CA" sub="ORDER NOW"  time="All Week: 11 AM – 1 AM" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Location;
