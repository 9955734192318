import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./detail.css"

const Detail = () => {
  const [scrollAmount, setScrollAmount] = useState(0);
  const [value, setValue] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setScrollAmount(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const moveAmount = scrollAmount / 20;

  return (
    <div>
      <Header />

      

      <div className=" mt-[100px] lg:mt-[200px]   px-[30px] lg:px-[10px] ">

      <div className="p-abo">
        <img
          src={require("../FoodMenu/foodImages/m1.webp")}
          alt=""
          className="order-img   max-w-[70%]"
          onMouseEnter={() => setValue(true)}
          onMouseLeave={() => setValue(false)}
        />

        <div
          className={`   ${
            value ? "order-class" : "d-none"
          }  absolute top-[-30px] right-[20%] uppercase justify-center items-center text-center leading-[25px] text-[20px] font-bold text-[green] h-[100px] flex items-center justify-center   w-[100px] bg-[#FFDD00] rounded-[50%]  `}
        >
          Order <br />
          Now
        </div>
      </div>
        <div className="uppercase  text-[72px] lg:text-[150px] font-bold   leading-[72px]  lg:leading-[120px] text-start  flex lg:justify-center items-center  text-[#075c40]  font-normal ">
          The Cheese
          <br />
          burger
        </div>

        <div className="uppercase text-[29px] text-[#74c3d5] text-start max-w-[1170px] mx-auto  mt-[100px] lg:mt-[200px] lg:w-[900px] ">
          ingredients
        </div>
        <div className="  text-[20px] lg:text-[25px]  leading-[26px]  lg:leading-[35px]  text-[#075c40]  mt-[16px] lg:mt-[24px] max-w-[1170px] mx-auto  lg:w-[900px] text-start ">
          Tomato Sauce, BBQ Sauce, Mozzarella Cheese, Aged Yellow Cheddar, Wagyu
          Balls, Pickled Gherkins, Red Onions, Spring Onions, Topped with House
          Sauce, White Sesame Seeds, a side of Shredded lettuce.
        </div>

        <div>
          <div className="move-text-1   pt-[100px] lg:pt-[200px] ">
            <div style={{ transform: `translateX(-${moveAmount}px)` }}>
              NOM NOM NOM PINZA! NOM NOM NOM PINZA! NOM NOM NOM PINZA! NOM NOM
              NOM PINZA! NOM NOM NOM PINZA! NOM NOM NOM PINZA!
            </div>
            <div style={{ transform: `translateX(${moveAmount}px)` }}>
              NOM NOM NOM PINZA! NOM NOM NOM PINZA! NOM NOM NOM PINZA! NOM NOM
              NOM PINZA! NOM NOM NOM PINZA! NOM NOM NOM PINZA!
            </div>
          </div>
        </div>

        <div className="flex  mt-[153px]  lg:max-w-[450px] w-full mx-auto ">
          <div className="nurtrision      leading-[80px]  uppercase text-[#075c40] font-bold ">
            nutrition
            <br />
            per slice
          </div>
          <div className=" kcl-text  mt-[-30px]   font-bold ">174</div>
          <div className="mt-[-50px] kcl-text font-bold">KCL</div>
        </div>

        <div className="move-text  pt-[153px] ">
          <div
            className="top"
            style={{ transform: `translateX(-${moveAmount}px)` }}
          >
            Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
            pizza,not a flatbread Not a pizza,
          </div>
          <div
            className="bottom"
            style={{ transform: `translateX(${moveAmount}px)` }}
          >
            Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
            pizza,not a flatbread Not a pizza,
          </div>
        </div>

        <div className="flex  mt-[153px]  max-w-[450px] w-full mx-auto ">
          <div className="nurtrision  text-[72px] font-bold  leading-[80px]  uppercase text-[#075c40] font-bold ">
           SHISH
            <br />
           TAWOOK
          </div>
          <div className=" kcl-text  mt-[-30px]   font-bold ">174</div>
          <div className="mt-[-50px] kcl-text font-bold">KCL</div>
        </div>

        <div className="uppercase text-[72px] lg:text-[150px] font-bold   leading-[72px]  lg:leading-[120px]  pb-[100px] lg:pb-[300px] leading-[195px] text-start mt-[150px]  flex justify-center items-center  text-[#075c40]  font-normal ">
          The Cheese
          <br />
          burger
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Detail;
