import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./about.css";

const AboutUs = () => {
  const [scrollAmount, setScrollAmount] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollAmount(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const moveAmount = scrollAmount / 10;
  const moveAmount1 = scrollAmount / 10;
  const moveAmount2 = scrollAmount / 10;
  const [showLeftImage, setShowLeftImage] = useState(false);
  const [ showRightImage, setShowRightImage] = useState(false);
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (
        leftImageRef.current &&
        leftImageRef.current.getBoundingClientRect().top <
          window.innerHeight / 2
      ) {
        setShowLeftImage(true);
      }
      if (
        rightImageRef.current &&
        rightImageRef.current.getBoundingClientRect().top <
          window.innerHeight / 2
      ) {
        setShowRightImage(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Header />

      <div className="overflow-x-hidden">
        <div className="  mt-[0px] lg:mt-[100px] flex justify-center  items-center lg:items-start gap-[20px]">
          <div className="relative">
            <div className="flex gap-[10px] justify-end items-end  top-[-100px]  left-[50%] absolute   ">
              <div className=" hidden md:flex flex-col justfy-end items-end w-full    ">
                <div className="pt-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="47"
                    viewBox="0 0 46 47"
                    fill="none"
                  >
                    <path
                      d="M1.01958 45.9407C2.71928 40.6105 3.79559 35.1215 6.1285 29.9953C8.49688 24.8139 11.6542 19.8976 15.5079 15.6942C23.2508 7.23213 33.858 1.98456 45.3746 1.57465C45.9333 1.56692 45.7258 0.699952 45.2326 0.702919C33.281 0.466499 22.2716 5.61174 14.0801 14.2381C9.98944 18.5311 6.72136 23.462 4.29662 28.8648C1.90738 34.2123 -0.0859817 39.9391 0.340649 45.8321C0.34424 46.1544 0.897023 46.338 1.01958 45.9407Z"
                      fill="#E8E6D9"
                    ></path>
                  </svg>
                </div>

                <div className="pr-[35px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                  >
                    <path
                      d="M15.9848 19.2382C19.0311 15.5147 22.2281 11.8725 25.0086 7.93143C25.905 6.68139 23.5664 5.18617 22.7052 6.46656C20.3137 10.0568 17.5259 13.3533 15.0091 16.8474C11.4182 11.7769 8.54926 6.22598 4.95844 1.15549C4.51836 0.529263 3.33405 0.891867 3.82383 1.65929C7.52276 7.31433 10.369 13.4593 14.1487 19.0492C14.5785 19.7157 15.4787 19.8806 15.9848 19.2382Z"
                      fill="#E8E6D9"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className=" hidden md:block text-[green] mt-[-20px]  font-normal text-[15px] leading-[15px]">
                WE ARE FAMOUS
                <br /> FOR OUR
                <br /> DOUGH!
              </p>
            </div>

            <img
              src={require("./about1.webp")}
              alt=""
              className={`slideInFromLeft ${
                showLeftImage ? "animate__backInDown" : ""
              }`}
            />
          </div>
          <div className="flex  mt-[100px]  flex-col justify-between items-start gap-[50px]">
            <img src={require("./about3.webp")} alt="" className="" />

            <div className=" text-[40px] md:text-[80px]  leading-[40px] md:leading-[80px] fontClass text-center font-bold  text-[green] ">
              <div>PINZARIA!</div>
              <div className="text-[#fd0] ">MADE WITH</div>
              <div className="text-[#fd0] ">LOVE</div>
            </div>

            <div className="flex justify-end w-full items-center">
              <img src={require("./about3.webp")} alt="" />
            </div>
          </div>
          <div className="pt-[50px]">
            <img src={require("./about2.webp")} alt=""   className={`slideInFromRight ${
                showRightImage ? "animate__backInRight" : ""
              }`} />
          </div>
        </div>
        <div className="flex justify-start items-start w-full">
          <div className="flex  w-full  justify-start ">
            <div className="ml-[100px]  mt-[-50px]">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="133"
                  viewBox="0 0 65 133"
                  fill="none"
                >
                  <path
                    d="M64.6054 131.429C54.9433 123.386 44.4251 116.507 35.7219 107.318C26.9338 98.0072 19.363 87.2773 13.8638 75.6991C2.78017 52.422 0.804277 25.8936 9.66599 1.55721C10.1212 0.386939 8.1414 0.0868183 7.73152 1.11674C-2.84258 25.8132 -1.41898 53.093 9.62815 77.4434C15.1141 89.5909 22.6219 100.564 31.8232 110.179C40.9395 119.673 51.1793 128.663 63.8066 132.752C64.4805 133.017 65.329 132.02 64.6054 131.429Z"
                    fill="#003B4A"
                  ></path>
                </svg>
              </div>
              <div className="ml-[40px] mt-[-20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59"
                  height="63"
                  viewBox="0 0 59 63"
                  fill="none"
                >
                  <path
                    d="M49.7914 31.8208C44.6093 22.3341 39.7234 12.6023 33.8638 3.48534C32.0174 0.563548 26.9297 4.17113 28.8689 7.04547C34.3264 15.0561 38.8378 23.6439 43.9891 31.8344C30.3993 35.0317 16.4184 36.3203 2.82859 39.5176C1.15307 39.9054 0.908435 42.6777 2.91993 42.3056C17.8183 39.3766 33.017 38.6369 47.848 35.4847C49.5987 35.1522 50.7019 33.417 49.7914 31.8208Z"
                    fill="#003B4A"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="text-container">
              <h5 className="text-start">
                hand-kneaded <br />
                crust
              </h5>
            </div>
          </div>
        </div>
        <div className="move-text">
          <div
            className="top"
            style={{ transform: `translateX(-${moveAmount}px)` }}
          >
            Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
            pizza,not a flatbread Not a pizza,
          </div>
          <div
            className="bottom"
            style={{ transform: `translateX(${moveAmount}px)` }}
          >
            Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
            pizza,not a flatbread Not a pizza,
          </div>
        </div>
        <div></div>
        <div className="mt-[200px] px-[50px] lg:px-[110px] flex flex-col md:flex-row justify-center items-start gap-[100px] ">
          <div>
            <div className=" text-[40px]   md:text-[72px] font-bold text-start text-[green] leading-[50px] md:leading-[94px] text-[#075c40] ">
              SO, WHAT IS <span className="text-[#74c3d5]">PINZARIA?</span>
            </div>
            <div className="what-text pt-[50px]">
              PINZARIA! is in a class all of its own. And rightly so.
            </div>
            <div className="what-text">
              It is the modern foodie’s answer to a wholesome, guilt-free and
              ridiculously delicious, hand-kneaded crust. The secret is the 80%
              hydration of our propietary special blend of Italian flour. This
              ensures a crisp, light, easy-to-digest, healthier and tastier
              base!
            </div>
            <div className="what-text">
              Nourishing, wholesome and completely irresistible, PINZARIA! is on a
              mission to introduce our uniquely healthy yet undeniably tasty
              menu to our community of thinkers, doers, movers, shakers and
              lovers of life.
            </div>
            <div className="what-text">
              We scoured Italy for the best cured meats such as our bresaola and
              beef spianata picante, our Italian tomatoes, and Italian cheeses.
              Our mozzarella is locally produced by the finest Italian craftsmen
              and our vegetables are sourced locally when possible, ensuring
              that we select only the freshest GMO-free veggies and herbs for
              our toppings.
            </div>
            <div className="what-text">
              With all tastes and flavours covered, and the choice of original
              or gluten-free crusts, we guarantee you will always be coming back
              for more.
            </div>
            <br></br><br></br>
          </div>
        </div>

        <div>
          <div className="move-text-1  pt-[200px] ">
            <div style={{ transform: `translateX(-${moveAmount1}px)` }}>
              NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM
              NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
            </div>
            <div style={{ transform: `translateX(${moveAmount1}px)` }}>
              NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM
              NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
            </div>
          </div>
        </div>

        <div className="      flex    justify-center md:justify-between    items-center md:items-start z-100">
          <div className="  hidden lg:block   relative  pt-[100px]">
            <img src={require("./about5.webp")} alt="" />
            <img
              src={require("./about6.webp")}
              alt=""
              className="absolute left-0 top-[32%]"
            />
            <img src={require("./about7.webp")} alt="" className="mt-[150px]" />
          </div>

          <div className="  w-[70%] md:w-[50%]  mx-auto ">
            <div className="pt-[200px] px-[50px] ">
              <div className="text-start text-[#74c3d5] leading-[54px] font-bold   text-[30px] lg:text-[72px] ">
                OUR TEAM
              </div>
              <div className="what-text">
                Our PINZARIA! menu caters to all tastes, flavour profiles and
                dietary requirements.
              </div>
              <div className="what-text">
                With an extensive offering of vegetarian and vegan items
                accompanying a main menu of meat and seafood options, we are
                certain we can satisfy all customers' needs.
              </div>
            </div>

            <div className="md:pl-[150px]">
              <div className="  pt-[100px] md:pt-[200px] px-[50px] lg:px-[0px] ">
                <div className="text-start text-[#075c40]  leading-[30px] md:leading-[54px] font-bold   text-[30px] lg:text-[52px] ">
                  OUR VISION & MISSION
                </div>
                <div className="what-text">
                  What do we want? To share PINZARIA! with our community and to
                  take our place as one of the best-known and loved brands
                  by offering healthy, wholesome and unique products in a space
                  that reflects our positive and uplifting values.
                </div>
                <div className="what-text">
                  We at PINZARIA! are dedicated to becoming your number one choice,
                  known for the freshest, tastiest and heathiest dough. PINZARIA!
                  aims to send good vibes and deeds back into our community,
                  remaining a champion for quality, for value, and for universal
                  kindness in our thoughts and actions.
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[-100px]  relative z-10  w-[30%]  md:w-auto   z-100">
            <img src={require("./about4.webp")} alt="" />
          </div>
        </div>
        <div className="move-text">
          <div style={{ transform: `translateX(-${moveAmount2}px)` }}>
            Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
            pizza,
          </div>
          <div style={{ transform: `translateX(${moveAmount2}px)` }}>
            Not a pizza, not a flatbread Not a pizza, not a flatbread Not a
            pizza,
          </div>
        </div>

        <div className="    w-full flex flex-col-reverse   lg:flex-row  gap-[40px] lg:gap-[100px] px-[30px] lg:px-[100px] justify-center items-center">
          <div className=" w-full   w-full  lg:w-[50%] pt-[100px] ">
            <div className="text-start  leading-[45px]  md:leading-[70px]  font-bold    text-[40px] md:text-[72px]     text-[#075c40] md:text-[52px] ">
              <div className="flex gap-[10px]">
                LESS
                <div
                  className="text-white"
                  style={{
                    textShadow:
                      "2px 2px 2px green, -2px -2px 2px green, 2px -2px 2px #075c40, -2px 2px 2px green",
                  }}
                >
                  CALORIES
                </div>
              </div>
              <div className="flex gap-[30px ]">
                <div
                  className="text-white flex gap-[30px]"
                  style={{
                    textShadow:
                      "2px 2px 2px green, -2px -2px 2px green, 2px -2px 2px #075c40, -2px 2px 2px green",
                  }}
                >
                  FOR
                </div>
                <span className="text-[#075c40] mr-[20px]">YOUR</span>
              </div>
              DOUGH
            </div>
            <div className="what-text">
              A question we get asked all the time is “what is in your dough?”.
              We never have and never will keep secrets about any of our
              ingredients, so here is the lowdown on our dough.⁣
            </div>
            <div className="what-text">
              We use a unique blend of flour created specifically for PINZARIA! – a
              blend of Naturally Dried Sourdough, Soy Flour and Fiber, Rice
              Flour and Soft Whole Wheat Flour. This blend gives us just the
              right amount of crunch in the crust but maintains a soft and
              fluffy centre.
            </div>
            <br></br>
          </div>
          <div className=" w-full  pt-[100px]  lg:pt-[0px]  lg:w-[50%]">
            <div className="flex justify-center items-center">
              <img src={require("./abopout8.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
