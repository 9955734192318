import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdMenu } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
function ResponsiveExample() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className=" flex  lg:hidden  justify-center items-center h-[50px]  border-[2px] border-[green] w-[50px]    rounded-[50%] p-[10px]">
        <IoMdMenu size={40} onClick={handleShow} />
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        responsive="lg"
        className="w-[100vw]  block lg:hidden  bg-[#74c3d5]"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="flex justify-between items-center w-full">
            <div>
              <RxCross1 size={30} onClick={handleClose} />
            </div>
            <div>
              <img src={require("./logo.png")} alt="" className="w-[100px]" />
            </div>
            <div className="fontClass flex justify-center items-center  w-[100px] h-[40px] text-[green] px-[8px] font-semibold text-[10px]  uppercase   transition duration-600 ease-in-out  bg-yellow-400">
              ORDER NOW
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Link to="/">
            <div className=" mt-[50px]  text-[white] text-center   text-[40px] md:text-[63px] font-bold   leading-[50px] md:leading-[72px]  uppercase py-[7px] px-[15px] transform rotate-[-10deg] ">
              Home
            </div>
          </Link>

          <Link to="/about">
            <div className="text-center  text-[white] text-[40px] md:text-[63px] font-bold  leading-[50px] md:leading-[72px]  uppercase py-[7px] px-[15px] transform rotate-[-10deg] ">
              About
            </div>
          </Link>
          <Link to="/menu">
            <div className=" text-center text-[white] text-[40px] md:text-[63px] font-bold  leading-[50px] md:leading-[72px]  uppercase py-[7px] px-[15px] transform rotate-[-10deg] ">
              Menu
            </div>
          </Link>
          <Link to="/locations">
            <div className=" text-center text-[white] text-[40px] md:text-[63px] font-bold  leading-[50px] md:leading-[72px]  uppercase py-[7px] px-[15px] transform rotate-[-10deg] ">
              Locations
            </div>
          </Link>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default ResponsiveExample;
