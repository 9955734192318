// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Archivo",

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.offcanvas-lg.show:not(.hiding) {
  width: 100vw;
}

.text-class {
  font-size: 2.25rem;
  text-transform: uppercase;
  color: #075c40;
  margin-right: 4px;
  line-height: 34px;
  margin-top: 0;
  text-align: center;
  margin-bottom: 0;
}

.hed-class {
  display: none;
}

.slick-current {
  position: relative;
  z-index: 1111;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;;AAEF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":["body {\r\n  font-family: \"Archivo\",\r\n\r\n}\r\n\r\n.App-header {\r\n  background-color: #282c34;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: calc(10px + 2vmin);\r\n  color: white;\r\n}\r\n\r\n@keyframes App-logo-spin {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n.offcanvas-lg.show:not(.hiding) {\r\n  width: 100vw;\r\n}\r\n\r\n.text-class {\r\n  font-size: 2.25rem;\r\n  text-transform: uppercase;\r\n  color: #075c40;\r\n  margin-right: 4px;\r\n  line-height: 34px;\r\n  margin-top: 0;\r\n  text-align: center;\r\n  margin-bottom: 0;\r\n}\r\n\r\n.hed-class {\r\n  display: none;\r\n}\r\n\r\n.slick-current {\r\n  position: relative;\r\n  z-index: 1111;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
